<template>
  <RevCard
    :id="item.listingId"
    class="space-y-32 p-24 md:space-y-56"
    data-qa="cart"
  >
    <Product
      :is-out-of-stock
      :item
      :quantity
      @remove="emit('remove')"
      @update-quantity="(quantity) => emit('update-quantity', quantity)"
    />

    <MobilePlanBundle
      v-if="item.mobilePlan"
      :mobile-plan-offer="item.mobilePlan.selectedOffer"
      @remove="emit('update', { ...item, mobilePlan: null })"
    />

    <Insurances
      v-if="!isOutOfStock && item.insuranceOffers.length > 0"
      :item
      :should-display-error
      :with-catch-up-modal="isFirstItemWithUnselectedInsurance"
      @ignore-catchup-modal="emit('ignore-catchup-modal')"
      @update="handleInsuranceUpdate"
    />

    <Shipping
      v-if="!hasDeliveryPromise"
      :is-collection-point-address-set
      :item
      :should-display-error
      @select-option="emit('update', item)"
    />
  </RevCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type {
  CartItem,
  CheckoutInsuranceOffer,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { RevCard } from '@ds/components/Card'

import Insurances from './components/Insurances/Insurances.vue'
import MobilePlanBundle from './components/MobilePlanBundle/MobilePlanBundle.vue'
import Product from './components/Product/Product.vue'
import Shipping from './components/Shipping/Shipping.vue'

const props = defineProps<{
  item: CartItem
  quantity: number
  isFirstItemWithUnselectedInsurance: boolean
  shouldDisplayError: boolean
  isCollectionPointAddressSet: boolean
}>()

const experiments = useExperiments()

const emit = defineEmits<{
  remove: []
  'update-quantity': [quantity: number]
  'ignore-catchup-modal': []
  update: [updatedItem: CartItem]
}>()

function handleInsuranceUpdate(
  updatedInsuranceOffers: CheckoutInsuranceOffer[],
) {
  return emit('update', {
    ...props.item,
    insuranceOffers: updatedInsuranceOffers,
  })
}

const isOutOfStock = computed(() => props.item.available !== 'yes')

const hasDeliveryPromise = computed(
  () =>
    experiments['experiment.deliveryMethodPosition'] ===
    'shippingDeliveryMethod',
)
</script>
