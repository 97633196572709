import type { ShippingOption } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'

function hasCollectionPointSelected(option: ShippingOption) {
  const isValid = option.choices.some(
    (choice) => choice.selected && choice.isCollectionPoint,
  )

  return isValid
}

export function hasCollectionPointError(
  options: ShippingOption[],
  isCollectionPointAddressSet: boolean,
) {
  const hasOptionWithCollectionPointSelected = options.some((option) =>
    hasCollectionPointSelected(option),
  )

  if (
    (hasOptionWithCollectionPointSelected && isCollectionPointAddressSet) ||
    !hasOptionWithCollectionPointSelected
  ) {
    return false
  }

  return true
}
